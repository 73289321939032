import React from 'react';

import { Text } from 'components/v2/Typography';
import theme from 'kb-shared/theme';

import { CareTeamPeople } from '../CareTeam.types';
import { DetailsWrapper } from '../styled';
import { ResponsibilityText } from './AssignedCareTeamPeople.styled';

export const AssignedCareTeamPeople = ({ careTeam }: { careTeam?: CareTeamPeople }) => {
  if ((!careTeam?.doctor && !careTeam?.clinicNurse && !careTeam?.cloudNurse) || !careTeam) {
    return null;
  }

  return (
    <>
      <Person responsibility="PROVIDER OF RECORD" name={careTeam.doctor} />
      <Person responsibility="CARE COORDINATOR OF RECORDS" name={careTeam.clinicNurse} />
      <Person responsibility="CLOUD NURSE" name={careTeam.cloudNurse} />
    </>
  );
};

const Person = ({ name, responsibility }: { name?: string; responsibility: string }) => {
  if (name == null) {
    return null;
  }

  return (
    <DetailsWrapper>
      <ResponsibilityText size="sm" color={theme.colors.neutral.primaryNavy}>
        {responsibility}
      </ResponsibilityText>
      <Text fontStyle="bold">{name}</Text>
    </DetailsWrapper>
  );
};
